<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="会员昵称"
            class="search-input"
            clearable
            v-model="searchObj.nickname"
          ></el-input>
          <el-select
            placeholder="全部状态"
            class="search-input ml-15"
            v-model="searchObj.status">
            <el-option
              v-for="(item, index) in order_status"
              :key="index"
              :label="item.status"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-input
            placeholder="核销人员"
            class="search-input ml-15"
            clearable
            v-model="searchObj.salesperson"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
          >
            <el-table-column label="会员昵称" prop="nickname"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column label="提交订单时间" prop="createTimeToStr"></el-table-column>
            <!-- <el-table-column label="提交订单时间" prop="createTime">
              <template slot-scope="scope">
                {{scope.row.createTime?scope.row.createTime.replace('T', ' ').split('.', 1)[0]:'--'}}
              </template>
            </el-table-column> -->
            <el-table-column label="状态">
              <template slot-scope="scope">
                {{ getString(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column label="核销时间">
              <template slot-scope="scope">
                {{scope.row.status==3?scope.row.photoTimeToStr:'--'}}
              </template>
            </el-table-column>
            <el-table-column label="核销人员">
              <template slot-scope="scope">
                {{scope.row.status==3?scope.row.salespersonName:'--'}}
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index"
import { getOrderList } from "@/api/transactions/order/orders_department";
export default {
  name: "credits_convert",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        nickname: "",
        status: "",
        salesperson: ""
      },
      order_status: [
        {
          id: 0,
          status: "未支付"
        },
        {
          id: 1,
          status: "已支付"
        },
        {
          id: 2,
          status: "已发货"
        },
        {
          id: 3,
          status: "已完成"
        },
        {
          id: 4,
          status: "待退款"
        },
        {
          id: 5,
          status: "已退款"
        },
        {
          id: -1,
          status: "退款失败"
        },
      ],
      tableData: [],
      activityId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100]
    };
  },
  mounted() {
    this.activityId = this.$route.query.id
    this.initData()
  },
  methods: {
    initData(searchData) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderType: 2,
        activityId: this.activityId
      }
      data = Object.assign(data, searchData)
      getOrderList(data).then(res => {
        this.total = res.data.body.total
        this.tableData = res.data.body.list
      })
    },
    // 搜索事件
    search() {
      let data = this.searchObj
      this.initData(data)
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        nickname: "",
        status: "",
        salesperson: ""
      };
      this.initData()
    },
    // 获取订单状态与类型
    getString(val) {
      let temp = "";
      this.order_status.forEach(e => {
        if (e.id == val) temp = e.status;
      }); 
      return temp;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  }
};
</script>
